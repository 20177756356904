import React from 'react';

export default function TLDR() {
  return(
    <ul className='my-info'>
      <li>Software Engineer focused on <b>Front End Development</b>, who loves creating engaging web products!</li>
      <li>Currently <b>seeking my next full time</b> opportunity in <b>Seattle, WA</b></li>
      <li>Recently completed a <b>full time, 480+ hour, 13 week Software Engineering Immersive</b> at 
        <a href='https://generalassemb.ly/education/software-engineering-immersive/seattle?ga_campaign=immersive-remote&ga_variation=sei-tile' 
           target='_blank'
           rel='noopener noreferrer'> General Assembly</a>
      </li>
      <li>Last full time position was doing <b>Android Development</b> as a Software Engineer at 
        <a 
          href='https://willowtreeapps.com/' 
          target='_blank'
          rel='noopener noreferrer'> WillowTree</a>
      </li>
      <li>Past experience solidified my understanding of <b>strong teamwork in an agile environment</b> as well as an <b>appreciation for candid conversations.</b></li>
      <li><b>Bachelor's Degree in Game Design and Development</b> means a creative mindset and problem solving from a different perspective.</li>
      <li>Attended the
        <a 
          href='https://www.rit.edu/computing/school-interactive-games-and-media' 
          target='_blank' 
          rel='noopener noreferrer'> Rochester Institute of Technology</a>
        , specifically the Golisano College of Information Technology and Science.</li>
      <li>Constantly building a knowledge base to share. Especially through <b>volunteering opportunities for teaching Computer Science</b> to the younger generation.</li>
    </ul>
  );
};
